import React from "react";
import "./chatCard.css";
import Chat_Sdk from "chatting_screen_sdk";

const ChatCard = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedlang, setSelectedLang] = React.useState("en");
  const [roomData, setRoomData] = React.useState("");

  const roomData1 = {
    receiverDetails: {
      userName: "Alex Carter",
      countryCode: "1",
      mobileNumber: "09876511121",
      userId: "5000",
      email: "Carter@example.com",
      profileUrl:
        "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },
    senderDetails: {
      userName: "Ethan Brooks",
      countryCode: "1",
      mobileNumber: "1278787890",
      userId: "5001",
      email: "Brooks@example.com",
      profileUrl:
        "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },
  };

  const roomData2 = {
    senderDetails: {
      userName: "Alex Carter",
      countryCode: "1",
      mobileNumber: "09876511121",
      userId: "5000",
      email: "Carter@example.com",
      profileUrl:
        "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },
    receiverDetails: {
      userName: "Ethan Brooks",
      countryCode: "1",
      mobileNumber: "1278787890",
      userId: "5001",
      email: "Brooks@example.com",
      profileUrl:
        "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },
  };

  return (
    <>
      {open ? null : (
        <header style={headerStyle}>
          <img src="images/FavMobb.png" alt="Logo" style={logoStyle} />
          <div style={headerTextStyle}>
            <span>Welcome</span>
            <span>To</span>
            <span>Chat App</span>
          </div>
          <a
            href="https://mobsdk.mobiloitte.io/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button style={trySdkButtonStyle}>Try SDK Now</button>
          </a>
        </header>
      )}

      {open ? (
        <Chat_Sdk
          language={selectedlang}
          appId={"20add5a7af348fc9"}
          appKey={"59f5ee30a947d18c5d35973906e314fd"}
          usersDetails={roomData}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "87vh",
            flexWrap: "wrap",
          }}
        >
          <div className="chat-card">
            <div>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
                src={"/users (1).png"}
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Alex Carter</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData1);
                }}
              >
                Start chat in English
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData1);
                }}
              >
                Start chat in Arabic
              </button>
            </div>
          </div>

          <div className="chat-card">
            <div>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
                src={"/download (8).jfif"}
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Ethan Brooks</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData2);
                }}
              >
                Start chat in English
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData2);
                }}
              >
                Start chat in Arabic
              </button>
            </div>
          </div>
        </div>
      )}

      {open ? null : (
        <footer style={footerStyle}>Powered by Mobiloitte Technologies</footer>
      )}
    </>
  );
};

export default ChatCard;

// CSS Styles
const headerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 20px",
  backgroundColor: "#BCC1C2",
  color: "#fff",
};

const logoStyle = {
  //   width: "50px",
  //   height: "50px",
};

const headerTextStyle = {
  display: "flex",
  gap: "10px",
  fontSize: "28px",
  fontWeight: "bold",
  // color: "#00A468",
};

const trySdkButtonStyle = {
  backgroundColor: "#00A468",
  color: "#fff",
  border: "none",
  padding: "15px 25px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};

const footerStyle = {
  textAlign: "center",
  padding: "10px",
  backgroundColor: "#00A468",
  color: "#fff",
  // position: "absolute",
  // bottom: 0,
  // width: "100%",
};
